  
const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL;

const CLOUDINARY_CLOUD_NAME = "dwjjtakfs";
const CLOUDINARY_API_KEY = 992935722916518;
const CLOUDINARY_API_SECRET = "C07VzBp8zn8A5NlZ9QcELB-B25w";
const CLOUDINARY_UPLOAD_PRESET = "uo6l2ljb_realse_client_preset";
const CLOUDINARY_UPLOAD_URL =
  "https://api.cloudinary.com/v1_1/dwjjtakfs/image/upload";

const MAP_MOB =
  "pk.eyJ1Ijoia2Nta2NtMTIzNDUiLCJhIjoiY2xoY2UwYWlvMTByeDNsbnVxMDgzNGkydSJ9.-eRx20Td26hSDCkttb5giQ";

export {
  GRAPHQL_URL,
  CLOUDINARY_UPLOAD_URL,
  CLOUDINARY_CLOUD_NAME,
  CLOUDINARY_API_KEY,
  CLOUDINARY_API_SECRET,
  CLOUDINARY_UPLOAD_PRESET,
  MAP_MOB
};

export const DOCTOR_SPECIALIZATION = [
  {
    title : 'Allergy and Immunology',
    description : 'Diagnose and manage diseases involving the immune system.'
  },
  {
    title : 'Anesthesiology',
    description : 'Provide anesthesia during surgery and pain management services.'
  },
  {
    title : 'Cardiology',
    description : 'Focus on diseases and conditions of the heart and the vascular system.'
  },
  {
    title : 'Dermatology',
    description : 'Treat skin diseases and conditions.'
  },
  {
    title : 'Emergency Medicine',
    description : 'Specialize in rapid recognition and treatment of trauma and acute illness.'
  },
  {
    title : 'Endocrinology',
    description : 'Treat diseases and disorders of the endocrine system and its hormones.'
  },
  {
    title : 'Family Medicine',
    description : 'Provide general healthcare services to families and individuals of all ages.'
  },
  {
    title : 'Gastroenterology',
    description : 'Focus on the diagnosis and treatment of diseases of the digestive system.'
  },
  {
    title : 'Geriatrics',
    description : 'Provide comprehensive healthcare for older adults.'
  },
  {
    title : 'Hematology and Oncology',
    description : 'Diagnose, prevent, and treat blood diseases (hematology) and cancer (oncology).'
  },
  {
    title : 'Infectious Disease',
    description : 'Deal with diseases caused by organisms such as bacteria, viruses, fungi, or parasites.'
  },
  {
    title : 'Internal Medicine',
    description : `Treat adult diseases, often serving as the patient's primary care physician.`
  },
  {
    title : 'Nephrology',
    description : 'Focus on kidney diseases and disorders.'
  },
  {
    title : 'Neurology',
    description : 'Diagnose and treat disorders of the nervous system.'
  },
  {
    title : 'Obstetrics and Gynecology',
    description : 'Care for the female reproductive system and associated disorders; obstetricians also deliver babies.'
  },
  {
    title : 'Ophthalmology',
    description : 'Diagnose and treat eye conditions.'
  },
  {
    title : 'Orthopedic Surgery',
    description : 'Treat injuries and diseases of the musculoskeletal system.'
  },{
    title : 'Otolaryngology (ENT - Ears, Nose, Throat)',
    description : 'Treat conditions of the ears, nose, and throat, and related areas of the head and neck.'
  },
  {
    title : 'Pediatrics',
    description : 'Specialize in the care of infants, children, and teenagers.'
  },
  {
    title : 'Psychiatry',
    description : 'Treat mental, emotional, and behavioral disorders.'
  },
  {
    title : 'Pulmonology',
    description : 'Treat lung conditions and diseases.'
  },
  {
    title : 'Radiology',
    description : 'Use imaging technologies to diagnose and treat diseases.'
  },
  {
    title : 'Rheumatology',
    description : 'Treat rheumatic diseases, including autoimmune diseases and diseases of the joints, muscles, and ligaments.'
  },
  {
    title : 'Surgery',
    description : ' Perform operations to treat diseases and injuries.'
  },
  {
    title : 'Urology',
    description : 'Focus on diseases of the urinary tract and the male reproductive system.'
  }
]

export const DOCTOR_SERVICES = [
  {
    description: 'Tooth cleaning ',
    value: 'Tooth cleaning',
  },
  {
    description: 'Root Canal Therap ',
    value: 'Root Canal Therap ',
  },
  {
    description: 'Implants ',
    value: 'Implants',
  },
  {
    description: 'Surgical Extractions ',
    value: 'Fissure Sealants ',
  },
  { description: 'Composite Bonding', value: 'Composite Bonding' },

];

export const SERVICE_DURATION = [
  {
    description: '15 minutes',
    value: '15 minutes',
  },
  {
    description: '30 minutes',
    value: '30 minutes',
  },
  {
    description: '45 minutes',
    value: '45 minutes',
  },
  {
    description: '1 hour',
    value: '1 hour',
  }  
];

export const APPOINTMENT_SESSION = [
  {
    description: 'morning',
    value: 'morning',
  },
  {
    description: 'afternoon',
    value: 'afternoon',
  },
  {
    description: 'evening',
    value: 'evening',
  }  
];

export const PAYMENT_PROVIDERS = [
  {
    description: 'Stripe',
    value: 'Stripe',
  } 
];

export const DAILY_STATES ={
  STATE_IDLE : 'STATE_IDLE',
  STATE_CREATING : 'STATE_CREATING',
  STATE_JOINING : 'STATE_JOINING',
  STATE_JOINED : 'STATE_JOINED',
  STATE_LEAVING : 'STATE_LEAVING',
  STATE_ERROR : 'STATE_ERROR'
}

export const STATUS_ACTIVE = 'active';
export const STATUS_TRIALING = 'trialing';
export const STATUS_SUSPENDED = 'suspended';
export const STATUS_INCOMPLETE = 'incomplete';
export const STATUS_CANCELLED = 'cancelled';

export const START_RATING =[
   {
    description: '1 ',
    value: '1',
  },
  {
    description: '2',
    value: '2',
  },
  {
    description: '3',
    value: '3',
  },
  {
    description: '4',
    value: '4',
  },
  {
    description: '5',
    value: '5',
  },
]

export const APPOINTMENT_STATUS =[
   {
    description: 'completed ',
    value: 'completed',
  },
  {
    description: 'rejected',
    value: 'rejected',
  }  
]

export const USER_ROLES =[
   {
    description: 'user ',
    value: 'user',
  },
  {
    description: 'admin',
    value: 'admin',
  }  
]

export const DONATION_TYPE =[
   {
    description: 'Tithes ',
    value: 'Tithes',
  },
  {
    description: 'Offerings',
    value: 'Offerings',
  }  
]
